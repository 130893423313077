
import React, { useEffect, useState } from "react";
import Inputfield from "../components/Inputfield";
import Inputtitle from "../components/Inputtitle";
import api from "../../api/baseapi";
import { Controller, useForm } from "react-hook-form";
import {
  addstringtoarray,
  handleAddClick,
  handleRemoveClick,
  hookAddinput,
  newfun
} from "../hooks/hookAddinput";
import Inputnew from "../components/Inputnew";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Qaullist from "../../dashboard/components/rijal/Qaullist"

const Editrajul = () => {
  // const [rajul, setrajul] = useState({})
  const [rajul, setid] = useState(null);

  
  const {id:ldd} = useParams()
  // const {isRajul_type} = id

  // const [idt, setidt] = useState(id)

  useEffect(() => {
    api.get(`/rajul/${ldd}`).then((res) => {
      setid(res.data.rajul);
      setisRajulType(res.data.rajul.isRajul_type);
      setisFissittah(res.data.rajul.isFisSittah);
      setisMudallis(res.data.rajul.isMudallis);
      setisMukhtalit(res.data.rajul.isMukhtalit);
      setisSahabi(res.data.rajul.isSahabi);
      setisStarred(res.data.rajul.isStarred);
      setbooks(res.data.rajul.books)
      setteachers(res.data.rajul.teachers)
      setstudents(res.data.rajul.students)

      res.data.rajul.tadeellist.length === 0 ?  setTadeellist([{
        name: "",
        deathYear: 0,
        quote: { arabic: "", bangla: "" },
        ref: "",
        grade: "",
      }]): setTadeellist(res.data.rajul.tadeellist)
      res.data.rajul.jarahlist.length === 0 ?  setJarahlist([{
        name: "",
        deathYear: 0,
        quote: { arabic: "", bangla: "" },
        ref: "",
        grade: "",
      }]): setJarahlist(res.data.rajul.jarahlist)
      res.data.rajul.otherlist.length === 0 ?  setOtherlist([{
        name: "",
        deathYear: 0,
        quote: { arabic: "", bangla: "" },
        ref: "",
        grade: "",
      }]): setOtherlist(res.data.rajul.otherlist)
    });
  }, []);

  useEffect(() => {
    // reset form with user data
    reset(rajul);
  }, [rajul]);

  console.log("rijals", rajul);
  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      iceCreamType: true,
    },
  });

  const [id,SetIdd] = useState(0)
  const [name, setname] = useState({ arabic: "old name", bangla: "old name" });
  const [f_name, setf_name] = useState({ arabic: "sefsef", bangla: "sefesf" });
  const [q_name, setq_name] = useState("sefsef");
  const [bd_date, setbd_date] = useState({ birth: 196, death: 256 });
  const [isStarred, setisStarred] = useState(false);
  const [tabqa, settabqa] = useState(0);
  const [isSahabi, setisSahabi] = useState(false);
  const [isRajul_type, setisRajulType] = useState({
    isMuaddal: true,
    isMajrooh: false,
    isMajhool: false,
    isMixed: false,
  });

  const [grade, setgrade] = useState("sdfsdf");
  const [isMudallis, setisMudallis] = useState(true);
  const [isMukhtalit, setisMukhtalit] = useState(true);
  const [isFisSittah, setisFissittah] = useState({
    bukhari: false,
    muslim: false,
    tirmizi: false,
    abuDawud: false,
    nasaee: false,
    ibnMajah: false,
  });
  const [books, setbooks] = useState([{ name: "sdfsdf", link: "sefsef" },{ name: "newnew", link: "sefsef" },{ name: "goodbook", link: "sefsef" }]);
  const [teachers, setteachers] = useState(["sefsefsfe", "sefsef"]);
  const [students, setstudents] = useState(["sefsefsfe", "sefsef"]);
  const [tadeellist, setTadeellist] = useState([
    {
      name: "sdfsdf",
      deathYear: 55,
      quote: { arabic: "sefsef", bangla: "sefsef" },
      ref: "sefsef",
      grade: "sefsef",
    },
  ]);
  const [jarahlist, setJarahlist] = useState([
    {
      name: "sdfsdf",
      deathYear: 55,
      quote: { arabic: "sefsef", bangla: "sefsef" },
      ref: "sefsef",
      grade: "sefsef",
    },
  ]);
  const [otherlist, setOtherlist] = useState([
    {
      name: "",
      deathYear: 0,
      quote: { arabic: "", bangla: "" },
      ref: "",
      grade: "",
    },])
  const [description, setdescription] = useState("sdfsfs");
  const [special_desc, setspecial_desc] = useState("sdfsdf");
  const [d_link, setd_link] = useState("sdfsdf");

  // Setting Strings for input
  const [teacherstring, setteacherstring] = useState([]);
  const [studentstring, setstudentstring] = useState([]);

  const handleChangee = (e, state, setstate) => {
    setstate({
      ...state,
      [e.target.name]: e.target.value,
    });
  };
  // const newfun = (state, setState, event, index, x) => {
  //   const { name, value, id } = event.target;
  //   // const new = id
  //   // const far = id
  //   const list = [...state];
  //   // list[index][name][id] = value;
  //   //  const item = {...x, ["quote"[event.target.name]] : event.target.value, }
  //   const item = {
  //     ...x,
  //     quote: { ...x.quote, [event.target.name]: event.target.value },
  //   };

  //   list[index] = item;
  //   setState(list);
  // };
  // setJarahlist({
  //   ...jarahlist,
  //   quote: {
  //     ...jarahlist.quote,
  //     bangla: e.target.value,
  //   },
  // })

  // console.log("rajul type new", isStarred);
  // console.log("user new", user);
  // console.log("jarah new", jarahlist);
  // console.log("tadeel new", tadeellist);
  // console.log('this is stricng', string)
  const addAndClearfield = (string, setstring, state, setstate) => {
    addstringtoarray(string, state, setstate);
    setstring("");
  };
  const handleChange = (e, state, setstate) => {
    setstate({
      ...state,
      [e.target.name]: e.target.value,
    });
  };
  const objOfBtns = (e, state, setstate, val) => {
    e.preventDefault();
    setstate({
      ...state,
      [e.target.name]: val,
    });
  };

  const rajuldata = {
    
 
    isStarred,
    tabqa,
    isSahabi,
    isRajul_type,
    isMudallis,
    isMukhtalit,
    isFisSittah,
    books,
    teachers,
    students,

    otherlist

  };
  if (jarahlist.length > 0 && !jarahlist[0].name === false ) {
    rajuldata.jarahlist = jarahlist;
  }else{
    rajuldata.jarahlist = [];

  }
  if (tadeellist.length > 0 && !tadeellist[0].name === false  ) {
    rajuldata.tadeellist = tadeellist;
  }else{
    rajuldata.tadeellist = [];
  }
  if (otherlist.length > 0 && !otherlist[0].name === false  ) {
    rajuldata.otherlist = otherlist;
  }else{
    rajuldata.otherlist = [];
  }
  // if (tadeellist.length > 0 && !tadeellist[0].name === '' ) {
  //   rajuldata.tadeellist = tadeellist;
  // }
  // if (jarahlist.length > 0 && !jarahlist[0].name === '' ) {
  //   rajuldata.jarahlist = jarahlist;
  // }
  console.log('newnew', rajul && rajul._id)

  const addRajul = () => {
    api.put(`/rajul/${ rajul && rajul._id}`, rajuldata);
    console.log("alldata new", rajuldata);
  };

  const newsubmit = (data) => {

   const {name,f_name, bd_date,id,q_name,grade,description,special_desc} = data
    // e.preventDefault();
    // ...data,
    // const neww = { ...data, ...rajuldata };
    const neww = { name,f_name, bd_date,id,q_name,grade,description,special_desc, ...rajuldata };
    api.put(`/rajul/${rajul && rajul._id}`, neww,{withCredentials:true}).then((res)=> toast.success("submit success"));

    // console.log(rajuldata, 'hello new')
    // console.log('data', data )
    // console.log('errors', errors)
    // console.log('testname', name)
    console.log("testname", neww);
  };
  const onChange = (event) => {
    console.log("change", !event.target.value);
  };
  const rainbow = ["array", "new"];

  return (
    <form className="" onSubmit={handleSubmit(newsubmit)}>
      
      <div className="container px-10 m-auto">
        <div className="h-[80px]">
        <div className="right-0 flex justify-end w-full h-12 mb-9">
          <button type="submit" className="bg-white hover:border-2 hover:border-[#fbff29] py-0 px-[32px] rounded-[50px] text-xl font-semibold text-[#4a4a4a] shadow-[-1px_1px_3px_0px_#d9d9d9]">
            সাবমিট করুন
          </button>

          {/* <input type="submit" className="bg-slate-600" value="সাবমিট করুন" /> */}
        </div>
      </div>

      <div className="id ">
        <Inputtitle title={"আইডি নং "} />

        <div className="flex flex-col items-center gap-4 mb-5 md:flex-row">

          <p className="w-[2] bg-[#d8e7e6] py-[2px] px-[17px] text-[#545454] font-bold rounded-[4px] shadow-[1px_1px_3px_-1px_#ababab] ">পূর্বের আইডি নং: 0{id}</p>

<div className="w-[200px]">
        <Inputfield
          placeholder={"id"}
          name="id"
          v_hook={register("id", {
            required: true,
            value:id +1,
            valueAsNumber: true,
            pattern:{
              value: /^(0|[1-9]\d*)(\.\d+)?$/
           },
          })}
          newstyle={
            errors.id && errors.id ? "border-[red] border-b-[3px]" : null
          }
        /></div>
        </div>
        </div>

        <div className="mb-5 single_input nameInput">
          <Inputtitle title={"রাবীর নাম"} />
          <div className="flex flex-col gap-4 md:flex-row">
            <Inputfield
              placeholder={"আরবী"}
              name="arabic"
              v_hook={register("name.arabic", {
                required: true,
                value: name.arabic || "",
              })}
              newstyle={
                errors.name && errors.name.arabic
                  ? "border-[red] border-b-[3px]"
                  : null
              }
            />

            <Inputfield
              placeholder={"বাংলা"}
              name="bangla"
              v_hook={register("name.bangla", {
                required: true,
                value: name.bangla || "",
              })}
              newstyle={
                errors.name && errors.name.bangla
                  ? "border-[red] border-b-[3px]"
                  : null
              }
            />
          </div>
        </div>

        <div className="mb-5 single_input Famous nameInput">
          <Inputtitle title={"প্রসিদ্ধ নাম"} />
          <div className="flex flex-col gap-4 md:flex-row">
            <Inputfield
              placeholder={"আরবী"}
              name="arabic"
              v_hook={register("f_name.arabic", {
                required: true,
                value: f_name.arabic || "",
              })}
              newstyle={errors.arabic ? "border-[red] border-b-[3px]" : null}
            />
            <Inputfield
              placeholder={"বাংলা"}
              name="bangla"
              v_hook={register("f_name.bangla", {
                required: true,
                value: f_name.bangla || "",
              })}
              newstyle={
                errors.f_name && errors.f_name.bangla
                  ? "border-[red] border-b-[3px]"
                  : null
              }
              // fun={(e) => handleChange(e, f_name, setf_name)}
            />
          </div>
        </div>

        <div className="mb-5 single_input">
          <div className="flex flex-col gap-4 md:flex-row">
            <div>
              <Inputtitle title={"কুনিয়াত"} />
              <Inputfield
                placeholder={"বাংলা"}
                v_hook={register("q_name", {
                  value: q_name || "",
                })}
                newstyle={errors.q_name ? "border-[red] border-b-[3px]" : null}
              />
            </div>

            <div>
              <Inputtitle title={"জন্ম-মৃত্যু সাল"} />
              <div className="flex">
                <Inputfield
                  placeholder={"জন্মসাল"}
                  name="birth"
                  v_hook={register("bd_date.birth", {
                    // value:q_name || ""
                  })}
                  newstyle={
                    errors.b_date && errors.b_date.birth
                      ? "border-[red] border-b-[3px]"
                      : null
                  }
                />
                <Inputfield
                  placeholder={"মৃত্যুসাল"}
                  name="death"
                  v_hook={register("bd_date.death", {
                    value: q_name || "",
                  })}
                  newstyle={
                    errors.b_date && errors.b_date.death
                      ? "border-[red] border-b-[3px]"
                      : null
                  }
                />
              </div>
            </div>
            <div className="tabqah">
              <Inputtitle title={"তাবকাহ"} />
              <Inputfield
                placeholder={"তাবকাহ"}
                name="tabqa"
                v_hook={register("tabqa", {
                  // value:q_name || ""
                })}
                newstyle={
                  errors.tabqa && errors.tabqa
                    ? "border-[red] border-b-[3px]"
                    : null
                }
              />
              {/* <Inputfield placeholder={'তাবকাহ'} fun={(e)=>settabqa(e.target.value)} /> */}
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-4 hukums md:flex md:flex-row">
          <div>
            <Inputtitle title={"গ্রহণযোগ্যতা"} />
            <div className="flex">
              {isRajul_type &&
                Object.entries(isRajul_type).map(([key, val], i) => (
                  <button
                    className={`${
                      val ? "bg-[#a9f7ff]" : "bg-slate-400"
                    } p-[3px_15px] mb-3 text-[17px] text-[#262626] shadow-[1px_1px_3px_0px_#b9b9b9] font-[600]`}
                    name={key}
                    onClick={(e) =>
                      objOfBtns(e, isRajul_type, setisRajulType, !val)
                    }
                    key={i}
                  >
                    {key === "isMuaddal" && <>মুতাদিল</>}
                    {key === "isMajrooh" && <>মাজরুহ</>}
                    {key === "isMajhool" && <>মাজহুল</>}
                    {key === "isMixed" && <>মিশ্রিত</>}
                  </button>
                ))}
            </div>
          </div>

          <div>
            <Inputtitle title={"কুতুবে সিত্তাহ"} />
            <div className="flex flex-wrap">
              {Object.entries(isFisSittah).map(([key, val], i) => (
                <button
                type="button"
                  className={`${
                    val ? "bg-[#a9f7ff]" : "bg-slate-400"
                  } p-[3px_15px] mb-3 text-[17px] text-[#262626] shadow-[1px_1px_3px_0px_#b9b9b9] font-[600]`}
                  name={key}
                  onClick={(e) =>
                    objOfBtns(e, isFisSittah, setisFissittah, !val)
                  }
                  key={i}
                >
                  {key === "bukhari" && <>বুখারী</>}
                  {key === "muslim" && <>মুসলিম</>}
                  {key === "tirmizi" && <>তিরমিযি</>}
                  {key === "abuDawud" && <>আবূ দাঊদ</>}
                  {key === "nasaee" && <>নাসাঈ</>}
                  {key === "ibnMajah" && <>ইবনে মাজাহ</>}
                </button>
              ))}
            </div>
          </div>
          <div className="flex buttons">
            <button
            type="button"
              className={`w-fit ${
                isMudallis && "bg-[#7de7f3]"
              } bg-slate-500 p-[3px_15px] mb-3 text-[17px] text-[#262626] shadow-[1px_1px_3px_0px_#b9b9b9] font-[600]`}
              onClick={() => setisMudallis(!isMudallis)}
            >
              মুদাল্লিস
            </button>

            <button
            type="button"
              className={`w-fit ${
                isMukhtalit && "bg-[#7de7f3]"
              } bg-slate-500 p-[3px_15px] mb-3 text-[17px] text-[#262626] shadow-[1px_1px_3px_0px_#b9b9b9] font-[600]`}
              onClick={() => setisMukhtalit(!isMukhtalit)}
            >
              মুখতালিত
            </button>
            {/* <button>Is starred</button> */}
            <div className="flex buttons">
              <button
              type="button"
                className={`w-fit ${
                  isSahabi ? "bg-[#7de7f3]" : "bg-slate-500"
                }  p-[3px_15px] mb-3 text-[17px] text-[#262626] shadow-[1px_1px_3px_0px_#b9b9b9] font-[600]`}
                onClick={() => setisSahabi(!isSahabi)}
              >
                সাহাবী
              </button>

              <button
              type="button"
                className={`w-fit ${
                  isStarred ? "bg-[#7de7f3]" : "bg-slate-500"
                }  p-[3px_15px] mb-3 text-[17px] text-[#262626] shadow-[1px_1px_3px_0px_#b9b9b9] font-[600]`}
                onClick={() => setisStarred(!isStarred)}
              >
                স্টার
              </button>
              {/* <button>Is starred</button> */}
            </div>
          </div>
        </div>

        <div className="string hukum">
          <div className="hukum">
            <Inputtitle title={"হুকুম"} />
            {/* <Inputfield
              placeholder={"হুকুম"}
              fun={(e) => setgrade(e.target.value)}
            /> */}
            <Inputfield
                  placeholder={"হুকুম"}
                  name="grade"
                  v_hook={register("grade", {
                    
                  })}
                  newstyle={
                    errors.b_date && errors.b_date.death
                      ? "border-[red] border-b-[3px]"
                      : null
                  }
                />
          </div>
        </div>

        <div className="mb-5 bookscontainer">
          <div className="div">
            <p className="i_title w-fit bg-[#a9f7ff] p-[3px_15px] mb-3 text-[17px] text-[#262626] shadow-[1px_1px_3px_0px_#b9b9b9] font-[600]">
              বই এর তালিকা:
            </p>
            {books.map((x, i) => {
              return (
                <div key={i} className="box first-letter">
                  <div className="flex flex-col md:flex-row">
                    <Inputfield
                      placeholder={"ইমামের নাম"}
                      name="name"
                      value={x.name}
                      fun={(e) => hookAddinput(books, setbooks, e, i)}
                    />

                    <Inputfield
                      placeholder={"লিংক"}
                      value={x.link}
                      name="link"
                      fun={(e) => hookAddinput(books, setbooks, e, i)}
                    />
                  </div>

                  <div className="btn-box">
                    {books.length !== 1 && (
                      <button
                        className="mr-5 bg-red-600 text-[16px] rounded font-[roboto]  text-white p-[5px_12px]"
                        onClick={() => handleRemoveClick(books, setbooks, i)}
                      >
                        Remove
                      </button>
                    )}
                    {books.length - 1 === i && (
                      <button
                        className=" bg-sky-600 text-[16px] rounded font-[roboto]  text-white p-[5px_12px]"
                        onClick={() =>
                          handleAddClick(books, setbooks, {
                            name: "",
                            link: "",
                          })
                        }
                      >
                        Add
                      </button>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="flex mb-5 teacherstudentscontainer">
          <div className="div teachers">
            <Inputtitle title={"উস্তাযের তালিকা"} />
            <Inputfield
              placeholder={"ইমামের নাম"}
              fun={(e) => setteacherstring(e.target.value)}
              value={teacherstring}
            />

            <button
              onClick={() =>
                teacherstring.length === 0
                  ? alert(" Please Enter student name")
                  : //  addstringtoarray(string,students, setstudents)
                    addAndClearfield(
                      teacherstring,
                      setteacherstring,
                      teachers,
                      setteachers
                    )
              }
            >
              add input
            </button>

            {teachers.map((x, i) => {
              return (
                <div key={i} className="box first-letter">
                  <p>{x}</p>
                  {/* <div className="flex flex-col md:flex-row">
                  <Inputfield
                    placeholder={"ইমামের নাম"}
                    // fun={(e) => addstringtoarray(x,teachers, setteachers)}
                  />

                </div> */}

                  <div className="btn-box">
                    {teachers.length !== 0 && (
                      <button
                        className="mr-5 bg-red-600 text-[16px] rounded font-[roboto]  text-white p-[5px_12px]"
                        onClick={() =>
                          handleRemoveClick(teachers, setteachers, i)
                        }
                      >
                        Remove
                      </button>
                    )}
                  </div>
                </div>
              );
            })}
          </div>

          <div className="div students">
            <Inputtitle title={"ছাত্র তালিকা"} />
            <Inputfield
              placeholder={"ইমামের নাম"}
              fun={(e) => setstudentstring(e.target.value)}
              value={studentstring}
            />

            <button
              onClick={() =>
                studentstring.length === 0
                  ? alert(" Please Enter student name")
                  : //  addstringtoarray(string,students, setstudents)
                    addAndClearfield(
                      studentstring,
                      setstudentstring,
                      students,
                      setstudents
                    )
              }
            >
              add input
            </button>

            {students.map((x, i) => {
              return (
                <div key={i} className="box first-letter">
                  <p>{x}</p>
                  <div className="flex flex-col md:flex-row"></div>

                  <div className="btn-box">
                    {students.length !== 0 && (
                      <button
                        className="mr-5 bg-red-600 text-[16px] rounded font-[roboto]  text-white p-[5px_12px]"
                        onClick={() =>
                          handleRemoveClick(students, setstudents, i)
                        }
                      >
                        Remove
                      </button>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="mb-5 tadeellistcontainer">
          <div className="div">
            <p className="i_title w-fit bg-[#a9f7ff] p-[3px_15px] mb-3 text-[17px] text-[#262626] shadow-[1px_1px_3px_0px_#b9b9b9] font-[600]">
              যে ইমামগণ তা’দীল করেছেন:
            </p>
            {tadeellist.map((x, i) => {
              return (
                <div key={i} className="box first-letter">
                  <div className="flex flex-col md:flex-row">
                    <Inputfield
                      placeholder={"ইমামের নাম"}
                      name="name"
                      value={x.name}
                      fun={(e) => hookAddinput(tadeellist, setTadeellist, e, i)}
                      // fun={(e) => {newfun(tadeellist, setTadeellist, e, i,x)}}
                    />
                    <Inputfield
                      placeholder={"মৃত্যু তারিখ"}
                      name="deathYear"
                      value={x.deathYear}
                      fun={(e) => hookAddinput(tadeellist, setTadeellist, e, i)}
                      // fun={(e) => {newfun(tadeellist, setTadeellist, e, i,x)}}
                    />

                    <Inputfield
                      placeholder={"arabic বক্তব্য"}
                      value={x.quote.arabic}
                      id="arabic"
                      name="arabic"
                      // fun={(e) => hookAddinput(tadeellist, setTadeellist, e, i)}
                      fun={(e) => {
                        newfun(tadeellist, setTadeellist, e, i, x);
                        // setJarahlist(
                        //   ...jarahlist,
                        //   ...x,
                        //   quote: {
                        //     ...x.quote,
                        //     arabic: e.target.value,
                        //   },
                        // );
                      }}
                    />
                    <Inputfield
                      placeholder={"bangla বক্তব্য"}
                      value={x.quote.bangla}
                      id="bangla"
                      name="bangla"
                      // fun={(e) => hookAddinput(jarahlist, setJarahlist, e, i)}
                      fun={(e) => {
                        newfun(tadeellist, setTadeellist, e, i, x);
                        // setJarahlist(
                        //   ...jarahlist,
                        //   ...x,
                        //   quote: {
                        //     ...x.quote,
                        //     arabic: e.target.value,
                        //   },
                        // );
                      }}
                    />
                    {/* <Inputfield
                    placeholder={"bangla বক্তব্য"}
                    value={x.quote.bangla}
                    name="quote"
                    fun={(e) => hookAddinput(tadeellist, setTadeellist, e, i)}
                  /> */}

                    <Inputfield
                      placeholder={"রেফারেন্স"}
                      value={x.ref}
                      name="ref"
                      fun={(e) => hookAddinput(tadeellist, setTadeellist, e, i)}
                    />
                    <Inputfield
                      placeholder={"মান"}
                      value={x.grade}
                      name="grade"
                      fun={(e) => hookAddinput(tadeellist, setTadeellist, e, i)}
                    />
                  </div>

                  <div className="btn-box">
                    {tadeellist.length !== 1 && (
                      <button
                        className="mr-5 bg-red-600 text-[16px] rounded font-[roboto]  text-white p-[5px_12px]"
                        onClick={() =>
                          handleRemoveClick(tadeellist, setTadeellist, i)
                        }
                      >
                        Remove
                      </button>
                    )}
                    {tadeellist.length - 1 === i && (
                      <button
                        className=" bg-sky-600 text-[16px] rounded font-[roboto]  text-white p-[5px_12px]"
                        onClick={() =>
                          handleAddClick(tadeellist, setTadeellist, {
                            name: "",
                            quote: { arabic: "", bangla: "" },
                            ref: "",
                            grade: "",
                          })
                        }
                      >
                        Add
                      </button>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>


        {jarahlist.length > 0 && <Qaullist array={jarahlist} setArray={setJarahlist} title={"যে ইমামগণ জারাহ করেছেন"} />}

        <div className="mb-5 tadeellistcontainer">
          <div className="div">
            <p className="i_title w-fit bg-[#a9f7ff] p-[3px_15px] mb-3 text-[17px] text-[#262626] shadow-[1px_1px_3px_0px_#b9b9b9] font-[600]">
              অন্যান্য বিষয়:
            </p>
            {otherlist.map((x, i) => {
              return (
                <div key={i} className="box first-letter">
                  <div className="flex flex-col md:flex-row">
                    <Inputfield
                      placeholder={"ইমামের নাম"}
                      name="name"
                      value={x.name}
                      fun={(e) => hookAddinput(otherlist, setOtherlist, e, i)}
                      // fun={(e) => {newfun(otherlist, setOtherlist, e, i,x)}}
                    />
                    <Inputfield
                      placeholder={"মৃত্যু তারিখ"}
                      name="deathYear"
                      value={x.deathYear}
                      fun={(e) => hookAddinput(otherlist, setOtherlist, e, i)}
                      // fun={(e) => {newfun(otherlist, setOtherlist, e, i,x)}}
                    />

                    <Inputfield
                      placeholder={"arabic বক্তব্য"}
                      value={x.quote.arabic}
                      id="arabic"
                      name="arabic"
                      // fun={(e) => hookAddinput(otherlist, setOtherlist, e, i)}
                      fun={(e) => {
                        newfun(otherlist, setOtherlist, e, i, x);
                        // setOtherlist(
                        //   ...otherlist,
                        //   ...x,
                        //   quote: {
                        //     ...x.quote,
                        //     arabic: e.target.value,
                        //   },
                        // );
                      }}
                    />
                    <Inputfield
                      placeholder={"bangla বক্তব্য"}
                      value={x.quote.bangla}
                      id="bangla"
                      name="bangla"
                      // fun={(e) => hookAddinput(otherlist, setOtherlist, e, i)}
                      fun={(e) => {
                        newfun(otherlist, setOtherlist, e, i, x);
                        // setOtherlist(
                        //   ...otherlist,
                        //   ...x,
                        //   quote: {
                        //     ...x.quote,
                        //     arabic: e.target.value,
                        //   },
                        // );
                      }}
                    />
                    {/* <Inputfield
                    placeholder={"bangla বক্তব্য"}
                    value={x.quote.bangla}
                    name="quote"
                    fun={(e) => hookAddinput(otherlist, setOtherlist, e, i)}
                  /> */}

                    <Inputfield
                      placeholder={"রেফারেন্স"}
                      value={x.ref}
                      name="ref"
                      fun={(e) => hookAddinput(otherlist, setOtherlist, e, i)}
                    />
                    <Inputfield
                      placeholder={"মান"}
                      value={x.grade}
                      name="grade"
                      fun={(e) => hookAddinput(otherlist, setOtherlist, e, i)}
                    />
                  </div>

                  <div className="btn-box">
                    {otherlist.length !== 1 && (
                      <button
                        className="mr-5 bg-red-600 text-[16px] rounded font-[roboto]  text-white p-[5px_12px]"
                        onClick={() =>
                          handleRemoveClick(otherlist, setOtherlist, i)
                        }
                      >
                        Remove
                      </button>
                    )}
                    {otherlist.length - 1 === i && (
                      <button
                        className=" bg-sky-600 text-[16px] rounded font-[roboto]  text-white p-[5px_12px]"
                        onClick={() =>
                          handleAddClick(otherlist, setOtherlist, {
                            name: "",
                            quote: { arabic: "", bangla: "" },
                            ref: "",
                            grade: "",
                          })
                        }
                      >
                        Add
                      </button>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        



        <div className="mb-5 download link">
          <Inputtitle title={"ডাউনলোড লিংক"} />
          <Inputfield placeholder={"ডাউনলোড লিংক"} />
        </div>
        <div className="mb-5 description section">
          <Inputtitle title={"রাবী সম্পর্কে বিস্তারিত আলোচনা"} />

          <textarea rows={10} class="mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
      focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
     
    "

              name="description"
              {...register("description", {
                value:description
               
                // value: f_name.bangla || "",
              })}
              
    
    ></textarea>
        </div>
        <div className="mb-5 description section">
          <Inputtitle title={"রাবী সম্পর্কে কিছু বিশেষ কথা"} />

          <textarea rows={10} class="mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
      focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
      
    "
    name="description"
    {...register("special_desc", {
      value:special_desc
     
      // value: f_name.bangla || "",
    })}
    
    ></textarea>
        </div>
      </div>
    </form>
  );
};

export default Editrajul;
