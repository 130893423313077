import React from 'react'

const SubmitBtn = () => {
  return (
    <div className="right-0 flex justify-end w-full h-12 mb-9">
          <button type="submit" className="bg-white border-2 border-transparent hover:border-2 hover:border-[#fbff29] py-0 px-[32px] rounded-[50px] text-xl font-semibold text-[#4a4a4a] shadow-[-1px_1px_3px_0px_#d9d9d9]">
            সাবমিট করুন
          </button>
        </div>
  )
}

export default SubmitBtn