import React from 'react'
import Inputtitle from '../Inputtitle';
import Inputfield from '../Inputfield';

const Booklist = ({books, hookAddinput, setbooks, handleAddClick, handleRemoveClick }) => {
  return (
    <div className="mb-5 bookscontainer">
          <div className="div">
          <Inputtitle title={"বই এর তালিকা:"} />
            {books.map((x, i) => {
              return (
                <div key={i} className="box first-letter">
                  <div className="flex flex-col my-3 md:flex-row">
                    <Inputfield
                      placeholder={"ইমামের নাম"}
                      name="name"
                      value={x.name}
                      fun={(e) => hookAddinput(books, setbooks, e, i)}
                    />

                    <Inputfield
                      placeholder={"লিংক"}
                      value={x.link}
                      name="link"
                      fun={(e) => hookAddinput(books, setbooks, e, i)}
                    />
                  </div>

                  <div className="btn-box">
                    {books.length !== 1 && (
                      <button
                      type="button"
                        className="mr-5 bg-[#858585] hover:bg-red-600 text-[16px] rounded font-[roboto]  text-white p-[5px_12px]"
                        onClick={() => handleRemoveClick(books, setbooks, i)}
                      >
                        Remove
                      </button>
                    )}
                    {books.length - 1 === i && (
                      <button
                      type="button"
                        className=" bg-sky-600 text-[16px] rounded font-[roboto]  text-white p-[5px_12px]"
                        onClick={() =>
                          handleAddClick(books, setbooks, {
                            name: "",
                            link: "",
                          })
                        }
                      >
                        Add
                      </button>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
  )
}

export default Booklist