import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";

function PaginatedItems({ itemsPerPage, Itemm, projectss }) {
    // We start with an empty list of items.
    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);

    useEffect(() => {
      // Fetch items from another resources.
      const endOffset = itemOffset + itemsPerPage;
      // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
      setCurrentItems(projectss.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(projectss.length / itemsPerPage));
    }, [itemOffset, itemsPerPage]);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % projectss.length;
      console.log(
        `User requested page number ${event.selected}, which is offset ${newOffset}`
      );
    //   window.scrollTo(0, 0);
      setItemOffset(newOffset);
    };

    return (
      <>
      
        <Itemm currentItems={currentItems} />
        <div className="m-auto mb-4 patination md:col-start-2">
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            // containerClassName="flex pl-0 list-none bg-white w-fit p-[4px] shadow-[#c3c3c3_1px_2px_4px_-1px]"
            pageClassName="mr-[3px] "
            pageLinkClassName="p-[1px_12px]  rounded-[3px]  hover:border-[red]"
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="< "
            activeLinkClassName=' border-[red] bg-[#d5d5d5]'
            previousClassName ='mr-[3px]'
            previousLinkClassName='p-[1px_12px] rounded-[3px] font-[fangsong]  '
            nextLinkClassName='p-[1px_12px]  rounded-[3px] font-[fangsong]  '
            renderOnZeroPageCount={null}
            containerClassName={ pageCount===1 ?'hidden': 'flex pl-0 list-none bg-white w-fit p-[4px] shadow-[#c3c3c3_1px_2px_4px_-1px]'}
          />
        </div>
        
      </>
    );
  }

export default PaginatedItems