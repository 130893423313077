import { Route, Routes } from 'react-router-dom';
import Admin from './dashboard/Admin';
import Test from './dashboard/components/Test';
import Testt from './dashboard/components/Testt';
import Editrajul from './dashboard/pages/Editrajul';
import AddRajul from './dashboard/pages/rijal/AddRajul';
import Rijal from './dashboard/pages/Rijal';
import Testrajul from './dashboard/pages/Testrajul';
import Rajuldetails from './pages/Rajuldetails';
import Navbar from './dashboard/components/Navbar'
import AddNarration from './dashboard/pages/tahqeeq/AddNarration';
import Tahqeeqat from './dashboard/pages/tahqeeq/Tahqeeqat';
import EditTahqeeq from './dashboard/pages/tahqeeq/EditTahqeeq';
import Bookdtls from './components/Bookdtls';
import Rijalfront from './pages/Rijalfront';
// import Tahqeeqatfront from './pages/Tahqeeq';
import Contact from './pages/Contact'
import Dashboard from './dashboard/pages/Dashboard';
import Login from './dashboard/components/Login'
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import Context from '../src/Context'
import { useState } from 'react';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react';
import splash from '../src/img/splash.png'
import Sidebar from './dashboard/components/Sidebar';
import Home from './pages/home/Home';
import api from './api/baseapi'
import Risalah from './pages/Risalah';
import Splash from './components/Splash';
import Modal from './components/Modal';
import { motion } from 'framer-motion'
import Testtt from './pages/Testtt';
import TahqeeqDtls from './pages/TahqeeqDtls';
import Tahqeeq from './pages/tahqeeq/Tahqeeq';
import Footer from './components/layout/Footer';
import Container from './components/layout/Container';

library.add(fas, fab)

function App() {
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [adminData, setAdmindata] = useState({});
  const [textArray, setTextArray] = useState([
    { arabic: { sanad: "", matan: "" }, bangla: "", takhrij: [] },
  ]);


  useEffect(() => {
    getmyData();
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);


  const getmyData = () => {
    api.get("/me", { withCredentials: true }).then(res => setAdmindata(res.data)).catch(err => setAdmindata(err))
  }

  const login = (email, password) => {
    api.post(
      "/user/login",
      {
        email,
        password,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    ).then(res => setMessage(res.data.message)).catch(err => setMessage(err))
  }
  const state = {

    message,
    login,
    getmyData,
    adminData,
    setMessage,
    textArray,
    setTextArray

  }
  console.log('hello world0')
  return (
    <>{isLoading ? <Splash /> : <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
       <Container>
        <Context.Provider value={state}>

          <Routes>

            <Route path='/' element={<div className="App  pt-[70px]  " ><Navbar /> </div>}>
              <Route path='/' element={<Home />} />

              <Route path='/add' element={<AddRajul />} />
              {/* <Rout e path='/' element={<Rajul />  } /> */}
              <Route path='/edit/:id' element={<Editrajul />} />
              <Route path='/tahqeeqedit/:id' element={<EditTahqeeq />} />
              {/* <Route path='/edit/:id' element={<Editrajul />  } /> */}
              <Route path='/narrators/:id' element={<Rajuldetails />} />
              {/* <Route path='/' element={<Rijalfront />  } /> */}
              {/* <Route path='/' element={<Tahqeeqatfront />  } /> */}

              {/* <Route path='/' element={<Testtt />  } /> */}
              <Route path='/tahqeeq' element={<Tahqeeq />} />
              <Route path='/narrators' element={<Rijalfront />} />
              <Route path='/risalah' element={<Risalah />} />

              <Route path='/tahqeeq/:id' element={<TahqeeqDtls />} />
              <Route path='/contact' element={<Contact />} />
              <Route path='/login' element={<Login />} />



            </Route>

            <Route path='/admin' element={<Dashboard />} >
              {/* <Sidebar /> */}

              <Route path='narrations' element={<div ><Tahqeeqat /></div>} />
              <Route path='addnarration' element={<div ><AddNarration /></div>} />
              <Route path='narrations/edit/:id' element={<div ><EditTahqeeq /></div>} />
              <Route path='addrajul' element={<div ><AddRajul /></div>} />
              <Route path='narrators' element={<div ><Rijal /></div>} />
              <Route path='narrators/edit/:id' element={<Editrajul />} />

            </Route>

          </Routes>

        </Context.Provider>
        <ToastContainer position="top-center" newestOnTop autoClose={1000} />

      </Container>
   
    </motion.div>}
    
    <Footer />


    </>
  );
}

export default App;
