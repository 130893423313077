import React, { useEffect, useState } from "react";
import Inputfield from "../../components/Inputfield";
import Inputtitle from "../../components/Inputtitle";
import api from "../../../api/baseapi";
import { useForm } from "react-hook-form";
import {
  addstringtoarray,
  handleAddClick,
  handleRemoveClick,
  hookAddinput,
} from "../../hooks/hookAddinput";
import { toast } from "react-toastify";
import SubmitBtn from "../../components/global/SubmitBtn";
import Booklist from "../../components/rijal/Booklist";
import TeacherStudentList from "../../components/rijal/TeacherStudentList";


const AddRajul = () => {

  useEffect(() => {
    api.get('/rijal').then((res) => setidd(res.data.rijalCount));

  }, [])
   useEffect(() => {

    reset(idd);
  }, []);

  const [idd, setidd] = useState(0)



  // console.log({idd})

  const {
    register,
    handleSubmit,

    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      iceCreamType: true,
    },
  });

  const [isStarred, setisStarred] = useState(false);
  const [tabqa, settabqa] = useState(0);
  const [isSahabi, setisSahabi] = useState(false);
  const [isRajul_type, setisRajulType] = useState({
    isMuaddal: false,
    isMajrooh: false,
    isMajhool: false,
    isMixed: false,
  });

  const [isMudallis, setisMudallis] = useState(false);
  const [isMukhtalit, setisMukhtalit] = useState(false);
  const [isFisSittah, setisFissittah] = useState({
    bukhari: false,
    muslim: false,
    tirmizi: false,
    abuDawud: false,
    nasaee: false,
    ibnMajah: false,
  });
  const [books, setbooks] = useState([{ name: "", link: "" }]);
  const [teachers, setteachers] = useState([]);
  const [students, setstudents] = useState([]);
  const [tadeellist, setTadeellist] = useState([
    {
      name: "",
      deathYear: 0,
      quote: { arabic: "", bangla: "" },
      ref: "",
      grade: "",
    },
  ]);
  let [jarahlist, setJarahlist] = useState([
    {
      name: "",
      deathYear: 0,
      quote: { arabic: "", bangla: "" },
      ref: "",
      grade: "",
    },
  ]);


  // Setting Strings for input
  const [teacherstring, setteacherstring] = useState([]);
  const [studentstring, setstudentstring] = useState([]);

  const newfun = (state, setState, event, index, x) => {

    const list = [...state];

    const item = {
      ...x,
      quote: { ...x.quote, [event.target.name]: event.target.value },
    };

    list[index] = item;
    setState(list);
  };

  const addAndClearfield = (string, setstring, state, setstate) => {
    addstringtoarray(string, state, setstate);
    setstring("");
  };

  const objOfBtns = (e, state, setstate, val) => {
    e.preventDefault();
    setstate({
      ...state,
      [e.target.name]: val,
    });
  };

  let rajuldata = {
    isStarred,
    tabqa,
    isSahabi,
    isRajul_type,
    isMudallis,
    isMukhtalit,
    isFisSittah,
    books,
    teachers,
    students,

    
  };
  if (jarahlist.length > 0 && jarahlist[0][Object.keys(jarahlist[0])[0]].trim() !== "") {
    rajuldata.jarahlist = jarahlist;
  }
  if (tadeellist.length > 0 && tadeellist[0][Object.keys(tadeellist[0])[0]].trim() !== "") {
    rajuldata.tadeellist = tadeellist;
  }


  const newsubmit = (data,e) => {
    e.preventDefault();
    // ...data,
    const neww = { ...data, ...rajuldata };
    api.post("/rajul/add", neww,{withCredentials:true}).then((res)=> toast.success("submit success"));

    console.log({data}
    )
    console.log("testname", neww);
  };

  return (
    <form className="" onSubmit={handleSubmit(newsubmit)}>
      
      <div className="container px-10 m-auto">
        <div className="h-[80px]">
        <SubmitBtn />
      </div>

        <div className="id ">
        <Inputtitle title={"আইডি নং "} />

        <div className="flex flex-col items-center gap-4 mb-5 md:flex-row">

          <p className="w-[2] bg-[#d8e7e6] py-[2px] px-[17px] text-[#545454] font-bold rounded-[4px] shadow-[1px_1px_3px_-1px_#ababab] ">পূর্বের আইডি নং: 0{idd}</p>

<div className="w-[200px]">
        <Inputfield
          placeholder={"id"}
          name="id"
          v_hook={register("id", {
            required: true,
            value:idd +1,
            valueAsNumber: true,
            pattern:{
              value: /^(0|[1-9]\d*)(\.\d+)?$/
           },
          })}
          newstyle={
            errors.id && errors.id ? "border-[red] border-b-[3px]" : null
          }
        /></div>
        </div>
        </div>

        <div className="mb-5 single_input nameInput">
          <Inputtitle title={"রাবীর নাম"} />
          <div className="flex flex-col gap-4 md:flex-row">
            <Inputfield
              placeholder={"আরবী"}
              name="arabic"
              v_hook={register("name.arabic", {
                required: true,
                // value: name.arabic || "",
              })}
              newstyle={
                errors.name && errors.name.arabic
                  ? "border-[red] border-b-[3px]"
                  : null
              }
            />

            <Inputfield
              placeholder={"বাংলা"}
              name="bangla"
              v_hook={register("name.bangla", {
                required: true,
              })}
              newstyle={
                errors.name && errors.name.bangla
                  ? "border-[red] border-b-[3px]"
                  : null
              }
            />
          </div>
        </div>

        <div className="mb-5 single_input Famous nameInput">
          <Inputtitle title={"প্রসিদ্ধ নাম"} />
          <div className="flex flex-col gap-4 md:flex-row">
            <Inputfield
              placeholder={"আরবী"}
              name="arabic"
              v_hook={register("f_name.arabic", {
                required: true,
                // value: f_name.arabic || "",
              })}
              newstyle={errors.arabic ? "border-[red] border-b-[3px]" : null}
            />
            <Inputfield
              placeholder={"বাংলা"}
              name="bangla"
              v_hook={register("f_name.bangla", {
                required: true,
                // value: f_name.bangla || "",
              })}
              newstyle={
                errors.f_name && errors.f_name.bangla
                  ? "border-[red] border-b-[3px]"
                  : null
              }
              // fun={(e) => handleChange(e, f_name, setf_name)}
            />
          </div>
        </div>

        <div className="mb-5 single_input">
          <div className="flex flex-col gap-4 md:flex-row">
            <div>
              <Inputtitle title={"কুনিয়াত"} />
              <Inputfield
                placeholder={"বাংলা"}
                v_hook={register("q_name", {
                  // value: q_name || "",
                })}
                newstyle={errors.q_name ? "border-[red] border-b-[3px]" : null}
              />
            </div>

            <div>
              <Inputtitle title={"জন্ম-মৃত্যু সাল"} />
              <div className="flex">
                <Inputfield
                  placeholder={"জন্মসাল"}
                  name="birth"
                  v_hook={register("bd_date.birth", {
                    // value:q_name || ""
                  })}
                  newstyle={
                    errors.b_date && errors.b_date.birth
                      ? "border-[red] border-b-[3px]"
                      : null
                  }
                />
                <Inputfield
                  placeholder={"মৃত্যুসাল"}
                  name="death"
                  v_hook={register("bd_date.death", {
                    // value: q_name || "",
                  })}
                  newstyle={
                    errors.b_date && errors.b_date.death
                      ? "border-[red] border-b-[3px]"
                      : null
                  }
                />
              </div>
            </div>
            <div className="tabqah">
              <Inputtitle title={"তাবকাহ"} />
              <Inputfield
                placeholder={"তাবকাহ"}
                name="tabqa"
                v_hook={register("tabqa", {
                  // value:q_name || ""
                })}
                newstyle={
                  errors.tabqa && errors.tabqa
                    ? "border-[red] border-b-[3px]"
                    : null
                }
              />
              {/* <Inputfield placeholder={'তাবকাহ'} fun={(e)=>settabqa(e.target.value)} /> */}
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-4 hukums md:flex md:flex-row">
          <div>
            <Inputtitle title={"গ্রহণযোগ্যতা"} />
            <div className="flex">
              {isRajul_type &&
                Object.entries(isRajul_type).map(([key, val], i) => (
                  <button
                    className={`${
                      val ? "bg-[#a9f7ff]" : "bg-slate-400"
                    } p-[3px_15px] mb-3 text-[17px] text-[#262626] shadow-[1px_1px_3px_0px_#b9b9b9] font-[600]`}
                    name={key}
                    onClick={(e) =>
                      objOfBtns(e, isRajul_type, setisRajulType, !val)
                    }
                    key={i}
                  >
                    {key === "isMuaddal" && <>মুতাদিল</>}
                    {key === "isMajrooh" && <>মাজরুহ</>}
                    {key === "isMajhool" && <>মাজহুল</>}
                    {key === "isMixed" && <>মিশ্রিত</>}
                  </button>
                ))}
            </div>
          </div>

          <div>
            <Inputtitle title={"কুতুবে সিত্তাহ"} />
            <div className="flex flex-wrap">
              {Object.entries(isFisSittah).map(([key, val], i) => (
                <button
                  className={`${
                    val ? "bg-[#a9f7ff]" : "bg-slate-400"
                  } p-[3px_15px] mb-3 text-[17px] text-[#262626] shadow-[1px_1px_3px_0px_#b9b9b9] font-[600]`}
                  name={key}
                  onClick={(e) =>
                    objOfBtns(e, isFisSittah, setisFissittah, !val)
                  }
                  key={i}
                >
                  {key === "bukhari" && <>বুখারী</>}
                  {key === "muslim" && <>মুসলিম</>}
                  {key === "tirmizi" && <>তিরমিযি</>}
                  {key === "abuDawud" && <>আবূ দাঊদ</>}
                  {key === "nasaee" && <>নাসাঈ</>}
                  {key === "ibnMajah" && <>ইবনে মাজাহ</>}
                </button>
              ))}
            </div>
          </div>
          <div >
          <Inputtitle title={"অন্যান্য"} />
          <div className="flex buttons">
            <button
            type="button"
              className={`w-fit ${
                isMudallis && "bg-[#7de7f3]"
              } bg-slate-500 p-[3px_15px] mb-3 text-[17px] text-[#262626] shadow-[1px_1px_3px_0px_#b9b9b9] font-[600]`}
              onClick={() => setisMudallis(!isMudallis) }
            >
              মুদাল্লিস
            </button>

            <button
            type="button"
              className={`w-fit ${
                isMukhtalit && "bg-[#7de7f3]"
              } bg-slate-500 p-[3px_15px] mb-3 text-[17px] text-[#262626] shadow-[1px_1px_3px_0px_#b9b9b9] font-[600]`}
              onClick={() => {setisMukhtalit(!isMukhtalit);}}
            >
              মুখতালিত
            </button>
            {/* <button>Is starred</button> */}
            <div className="flex buttons">
              <button
              type="button"
                className={`w-fit ${
                  isSahabi ? "bg-[#7de7f3]" : "bg-slate-500"
                }  p-[3px_15px] mb-3 text-[17px] text-[#262626] shadow-[1px_1px_3px_0px_#b9b9b9] font-[600]`}
                onClick={() => setisSahabi(!isSahabi)}
              >
                সাহাবী
              </button>

              <button
              type="button"
                className={`w-fit ${
                  isStarred ? "bg-[#7de7f3]" : "bg-slate-500"
                }  p-[3px_15px] mb-3 text-[17px] text-[#262626] shadow-[1px_1px_3px_0px_#b9b9b9] font-[600]`}
                onClick={() => setisStarred(!isStarred)}
              >
                স্টার
              </button>
              {/* <button>Is starred</button> */}
            </div>
          </div>
          </div>
          
        </div>

        <div className="mb-5 string hukum">
          <div className="hukum">
            <Inputtitle title={"হুকুম"} />
            {/* <Inputfield
              placeholder={"হুকুম"}
              fun={(e) => setgrade(e.target.value)}
            /> */}
            <Inputfield
                  placeholder={"হুকুম"}
                  name="grade"
                  v_hook={register("grade", {
                    
                  })}
                  newstyle={
                    errors.grade && errors.grade
                      ? "border-[red] border-b-[3px]"
                      : null
                  }
                />
          </div>
        </div>
        <Booklist books={books} handleAddClick={handleAddClick} setbooks={setbooks} handleRemoveClick={handleRemoveClick} hookAddinput={hookAddinput} />

        <TeacherStudentList teachers={teachers} setteachers={setteachers} students={students} setstudents={setstudents} teacherstring={teacherstring} setteacherstring={setteacherstring} studentstring={studentstring} setstudentstring={setstudentstring} addAndClearfield={addAndClearfield} handleRemoveClick={handleRemoveClick} />





        <div className="mb-5 tadeellistcontainer">
          <div className="div">
          <Inputtitle title={"যে ইমামগণ তা’দীল করেছেন:"} />

            {tadeellist.map((x, i) => {
              return (
                <div key={i} className="box first-letter ">
                  <div className="flex flex-col my-3 md:flex-row">
                    <Inputfield
                      placeholder={"ইমামের নাম"}
                      name="name"
                      value={x.name}
                      fun={(e) => hookAddinput(tadeellist, setTadeellist, e, i)}
                      // fun={(e) => {newfun(tadeellist, setTadeellist, e, i,x)}}
                    />
                    <Inputfield
                      placeholder={"মৃত্যু তারিখ"}
                      name="deathYear"
                      value={x.deathYear}
                      fun={(e) => hookAddinput(tadeellist, setTadeellist, e, i)}
                      // fun={(e) => {newfun(tadeellist, setTadeellist, e, i,x)}}
                    />

                    <Inputfield
                      placeholder={"arabic বক্তব্য"}
                      value={x.quote.arabic}
                      id="arabic"
                      name="arabic"
                      // fun={(e) => hookAddinput(tadeellist, setTadeellist, e, i)}
                      fun={(e) => {
                        newfun(tadeellist, setTadeellist, e, i, x);
                        // setJarahlist(
                        //   ...jarahlist,
                        //   ...x,
                        //   quote: {
                        //     ...x.quote,
                        //     arabic: e.target.value,
                        //   },
                        // );
                      }}
                    />
                    <Inputfield
                      placeholder={"bangla বক্তব্য"}
                      value={x.quote.bangla}
                      id="bangla"
                      name="bangla"
                      // fun={(e) => hookAddinput(tadeellist, setTadeellist, e, i)}
                      fun={(e) => {
                        newfun(tadeellist, setTadeellist, e, i, x);
                        // setJarahlist(
                        //   ...jarahlist,
                        //   ...x,
                        //   quote: {
                        //     ...x.quote,
                        //     arabic: e.target.value,
                        //   },
                        // );
                      }}
                    />
                    {/* <Inputfield
                    placeholder={"bangla বক্তব্য"}
                    value={x.quote.bangla}
                    name="quote"
                    fun={(e) => hookAddinput(tadeellist, setTadeellist, e, i)}
                  /> */}

                    <Inputfield
                      placeholder={"রেফারেন্স"}
                      value={x.ref}
                      name="ref"
                      fun={(e) => hookAddinput(tadeellist, setTadeellist, e, i)}
                    />
                    <Inputfield
                      placeholder={"মান"}
                      value={x.grade}
                      name="grade"
                      fun={(e) => hookAddinput(tadeellist, setTadeellist, e, i)}
                    />
                  </div>

                  <div className="btn-box">
                    {tadeellist.length !== 1 && (
                      <button
                        className="mr-5 bg-red-600 text-[16px] rounded font-[roboto]  text-white p-[5px_12px]"
                        onClick={() =>
                          handleRemoveClick(tadeellist, setTadeellist, i)
                        }
                      >
                        Remove
                      </button>
                    )}
                    {tadeellist.length - 1 === i && (
                      <button
                        className=" bg-sky-600 text-[16px] rounded font-[roboto]  text-white p-[5px_12px]"
                        onClick={() =>
                          handleAddClick(tadeellist, setTadeellist, {
                            name: "",
                            quote: { arabic: "", bangla: "" },
                            ref: "",
                            grade: "",
                          })
                        }
                      >
                        Add
                      </button>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="mb-5 tadeellistcontainer">
          <div className="div">
          <Inputtitle title={"যে ইমামগণ জারাহ করেছেন"} />
            {jarahlist.map((x, i) => {
              return (
                <div key={i} className="box first-letter">
                  <div className="flex flex-col my-3 md:flex-row">
                    <Inputfield
                      placeholder={"ইমামের নাম"}
                      name="name"
                      value={x.name}
                      fun={(e) => hookAddinput(jarahlist, setJarahlist, e, i)}
                      // fun={(e) => {newfun(jarahlist, setJarahlist, e, i,x)}}
                    />
                    <Inputfield
                      placeholder={"মৃত্যু তারিখ"}
                      name="deathYear"
                      value={x.deathYear}
                      fun={(e) => hookAddinput(jarahlist, setJarahlist, e, i)}
                      // fun={(e) => {newfun(jarahlist, setJarahlist, e, i,x)}}
                    />

                    <Inputfield
                      placeholder={"arabic বক্তব্য"}
                      value={x.quote.arabic}
                      id="arabic"
                      name="arabic"
                      // fun={(e) => hookAddinput(jarahlist, setJarahlist, e, i)}
                      fun={(e) => {
                        newfun(jarahlist, setJarahlist, e, i, x);
                        // setJarahlist(
                        //   ...jarahlist,
                        //   ...x,
                        //   quote: {
                        //     ...x.quote,
                        //     arabic: e.target.value,
                        //   },
                        // );
                      }}
                    />
                    <Inputfield
                      placeholder={"bangla বক্তব্য"}
                      value={x.quote.bangla}
                      id="bangla"
                      name="bangla"
                      // fun={(e) => hookAddinput(jarahlist, setJarahlist, e, i)}
                      fun={(e) => {
                        newfun(jarahlist, setJarahlist, e, i, x);
                        // setJarahlist(
                        //   ...jarahlist,
                        //   ...x,
                        //   quote: {
                        //     ...x.quote,
                        //     arabic: e.target.value,
                        //   },
                        // );
                      }}
                    />
                    {/* <Inputfield
                    placeholder={"bangla বক্তব্য"}
                    value={x.quote.bangla}
                    name="quote"
                    fun={(e) => hookAddinput(jarahlist, setJarahlist, e, i)}
                  /> */}

                    <Inputfield
                      placeholder={"রেফারেন্স"}
                      value={x.ref}
                      name="ref"
                      fun={(e) => hookAddinput(jarahlist, setJarahlist, e, i)}
                    />
                    <Inputfield
                      placeholder={"মান"}
                      value={x.grade}
                      name="grade"
                      fun={(e) => hookAddinput(jarahlist, setJarahlist, e, i)}
                    />
                  </div>

                  <div className="btn-box">
                    {jarahlist.length !== 1 && (
                      <button
                        className="mr-5 bg-red-600 text-[16px] rounded font-[roboto]  text-white p-[5px_12px]"
                        onClick={() =>
                          handleRemoveClick(jarahlist, setJarahlist, i)
                        }
                      >
                        Remove
                      </button>
                    )}
                    {jarahlist.length - 1 === i && (
                      <button
                        className=" bg-sky-600 text-[16px] rounded font-[roboto]  text-white p-[5px_12px]"
                        onClick={() =>
                          handleAddClick(jarahlist, setJarahlist, {
                            name: "",
                            quote: { arabic: "", bangla: "" },
                            ref: "",
                            grade: "",
                          })
                        }
                      >
                        Add
                      </button>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        


        <div className="mb-5 download link">
          <Inputtitle title={"ডাউনলোড লিংক"} />
          <Inputfield placeholder={"ডাউনলোড লিংক"} name="d_link"
              v_hook={register("d_link", {
               
                // value: f_name.bangla || "",
              })} />
        </div>
        <div className="mb-5 description section">
          <Inputtitle title={"রাবী সম্পর্কে বিস্তারিত আলোচনা"} />

          <textarea rows={10} class="mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
      focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
     
    "

              name="description"
              {...register("description", {
               
                // value: f_name.bangla || "",
              })}
              
    
    ></textarea>
        </div>
        <div className="mb-5 description section">
          <Inputtitle title={"রাবী সম্পর্কে কিছু বিশেষ কথা"} />

          <textarea rows={10} class="mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
      focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
      
    "
    name="description"
    {...register("special_desc", {
     
      // value: f_name.bangla || "",
    })}
    
    ></textarea>
        </div>
      </div>
    </form>
  );
};

export default AddRajul;
