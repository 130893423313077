import React from 'react'
import Inputfield from '../Inputfield';
import Inputtitle from '../Inputtitle';

const TeacherStudentList = ({
    teacherstring, 
    setteacherstring, 
    teachers, 
    setteachers, 
    addAndClearfield, 
    handleRemoveClick,
    studentstring,
    setstudentstring,
    students,
    setstudents
 }) => {
  return (
    <div className="flex mb-5 teacherstudentscontainer">
          <div className="div teachers">
            <Inputtitle title={"উস্তাযের তালিকা"} />
            <Inputfield
              placeholder={"উস্তাযের নাম"}
              fun={(e) => setteacherstring(e.target.value)}
              value={teacherstring}
            />

            <button
            className=" bg-sky-600 text-[16px] rounded font-[roboto] mt-2  text-white p-[5px_12px]"
            type="button"
              onClick={() =>
                teacherstring.length === 0
                  ? alert(" Please Enter student name")
                  : //  addstringtoarray(string,students, setstudents)
                    addAndClearfield(
                      teacherstring,
                      setteacherstring,
                      teachers,
                      setteachers
                    )
              }
            >
              add input
            </button>

            {teachers.map((x, i) => {
              return (
                <div key={i} className="box first-letter">
                  <p>{x}</p>

                  <div className="my-3 btn-box">
                    {teachers.length !== 0 && (
                      <button
                      type="button"
                        className="mr-5 bg-[#858585] hover:bg-red-600 text-[16px] rounded font-[roboto]  text-white p-[5px_12px]"
                        onClick={() =>
                          handleRemoveClick(teachers, setteachers, i)
                        }
                      >
                        Remove
                      </button>
                    )}
                  </div>
                </div>
              );
            })}
          </div>

          <div className="div students">
            <Inputtitle title={"ছাত্র তালিকা"} />
            <Inputfield
              placeholder={"ছাত্রের নাম"}
              fun={(e) => setstudentstring(e.target.value)}
              value={studentstring}
            />

            <button
            type="button"
            className=" bg-sky-600 text-[16px] rounded font-[roboto] mt-2  text-white p-[5px_12px]"
              onClick={() =>
                studentstring.length === 0
                  ? alert(" Please Enter student name")
                  : //  addstringtoarray(string,students, setstudents)
                    addAndClearfield(
                      studentstring,
                      setstudentstring,
                      students,
                      setstudents
                    )
              }
            >
              add input
            </button>

            {students.map((x, i) => {
              return (
                <div key={i} className="my-3 box first-letter">
                  <p>{x}</p>
                  <div className="flex flex-col md:flex-row "></div>

                  <div className="btn-box">
                    {students.length !== 0 && (
                      <button
                      type="button"
                        className="mr-5 bg-[#858585] hover:bg-red-600 text-[16px] rounded font-[roboto]  text-white p-[5px_12px]"
                        onClick={() =>
                          handleRemoveClick(students, setstudents, i)
                        }
                      >
                        Remove
                      </button>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
  )
}

export default TeacherStudentList