import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import api from "../../api/baseapi";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "react-headless-accordion";
import Pagination from "react-js-pagination";
import SearchItem from "../../components/SearchItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "../../components/Loader";
import TahqeeqCard from "../../components/tahqeeq/TahqeeqCard";
import Filtercounter from "../../components/Filtercounter";
import Cardbtn from "../../components/Cardbtn";
import PaginatedItems from "../../components/pagination/PaginatedItems";
import Category from "../../components/Category";

function Tahqeeq() {
  let [searchParams] = useSearchParams();
  const category = searchParams.get("category");
  const filter = searchParams.get("filter");
  const search = searchParams.get("search");
  console.log({ category });
  const [tahqeeqat, settahqeeq] = useState([]);
  const [searchkey, settSearch] = useState(null);
  const [categories, setcategories] = useState([]);
  const { tahqeeqs, resultPerPage, filteredTahqeeqCount, tahqeeqCount } =
    tahqeeqat;
  const [currentPage, setCurrentPage] = useState(1);
  const setCurrentPageNo = (e) => {
    setCurrentPage(e);
  };
  const count = filteredTahqeeqCount;
  // const { categorys } = categories;
  console.log("rijals", tahqeeqs);
  console.log({ tahqeeqat });
  console.log("categories", categories);

  useEffect(() => {
    async function filterhandler() {
      if (category !== null) {
        return await api
          .get("/categorys")
          .then((response1) => {
            setcategories(response1.data.categorys);
            return api.get(`/tahqeeqs?category=${category}`);
          })
          .then((response2) => {
            settahqeeq(response2.data);
          })
          .catch((error) => {
            console.error(error);
          });
      }

      if (filter !== null) {
        return await api
          .get(
            `/tahqeeqs?${
              filter === "isMaqbool" && "isMardood" ? "isRewyat" : "type"
            }.${filter}=${filter && true}&page=${currentPage}`
          )
          .then((res) => settahqeeq(res.data));
      }
      if (search !== null) {
        return await api
          .get(`/tahqeeqs?keyword=${search}`)
          .then((res) => settahqeeq(res.data));
      }
      if (currentPage > 1) {
        api
          .get(`/tahqeeqs?&page=${currentPage}`)
          .then((res) => settahqeeq(res.data));
      } else {
        api.get("/categorys").then((res) => setcategories(res.data.categorys));
        api.get("/tahqeeqs").then((res) => settahqeeq(res.data));
      }
    }
    filterhandler();
  }, [category, filter, search, currentPage]);
  // }
  function findNodeIdByName(tree, name) {
    const stack = [...tree];
    while (stack.length) {
      const node = stack.pop();
      if (node.slug === name) {
        return node._id;
      }
      stack.push(...node.children);
    }
    return null;
  }

  // Example usage
  // const nodeId = findNodeIdByName(categories, 'salate-hat-badhar-bidhan');

  // console.log(nodeId,'myiddd'); // Output: 8
  console.log(category, "newcattttt");

  // console.log(findIdByKey(tahqeeqat && tahqeeqs.category , 'shaban-masher-fazilat' ),'catteeegrr')

  // fetchData();

  const handlecat = (categories) => {
    let myCat = [];
    for (let category of categories) {
      myCat.push(
        // <Accordiont title={category.name} slug={category.slug} content={category.children.length > 0 ?(<ul>{handlecat(category.children)}</ul>):null} />

        // <Accordiont title={category.name} slug={category.slug} content={category.children.length > 0 ?(<ul>{handlecat(category.children)}</ul>):null} />
        <Accordion>
          <AccordionItem className="bg-[#e8ff1b]">
            {({ open }) => (
              <>
                <AccordionHeader className="flex items-center justify-between w-full p-4 text-gray-600 border-b">
                  {category.parentId ? (
                    <>
                      <Link to={`?category=${category.slug}`}>
                        <h3 className={`accordion-title `}>{category.name}</h3>
                      </Link>{" "}
                      {category.children.length > 0 && (
                        <p style={{ marginLeft: "20px" }}>
                          {open ? (
                            <FontAwesomeIcon icon="fa-solid fa-caret-up" />
                          ) : (
                            <FontAwesomeIcon icon="fa-solid fa-caret-down" />
                          )}
                        </p>
                      )}{" "}
                    </>
                  ) : (
                    <>
                      <h3 className={`accordion-title `}>{category.name} </h3>
                      <p style={{ marginLeft: "20px" }}>
                        {open ? (
                          <FontAwesomeIcon icon="fa-solid fa-caret-up" />
                        ) : (
                          <FontAwesomeIcon icon="fa-solid fa-caret-down" />
                        )}
                      </p>
                    </>
                  )}
                </AccordionHeader>

                <AccordionBody>
                  <div className="accordion-body">
                    {category.children.length > 0 && (
                      <AccordionItem>
                        {({ open }) => (
                          <>
                            {" "}
                            <AccordionHeader className="bg-[#fafafa] ml-4 pr-4 w-full">
                              {
                                <ul className="ulul">
                                  {handlecat(category.children)}
                                </ul>
                              }
                              {/* {category.children.length > 0 ?(<ul>{handlecat(category.children):null} */}
                            </AccordionHeader>
                          </>
                        )}

                        {/* <AccordionBody>
                        <div className="accordion-body">
                            Lorem ipsum dolor sit amet.
                        </div>
                    </AccordionBody> */}
                      </AccordionItem>
                    )}
                  </div>
                </AccordionBody>
              </>
            )}
          </AccordionItem>
        </Accordion>
        // <li key={category.name}>
        //   {category.name}
        //   {category.children.length > 0 ?(<ul>{handlecat(category.children)}</ul>):null}
        // </li>
      );
    }
    return myCat;
  };
  const sechandlecat = (categories) => {
    let myCat = [];
    for (let category of categories) {
      myCat.push(
        // <Accordion title={category.name} slug={category.slug} content={category.children.length > 0 ?(<ul>{handlecat(category.children)}</ul>):null} />
        <li key={category.name}>
          {category.name}
          {/* {category.length > 0 ? (<ul>{handlecat(category)}</ul>): null} */}
          {category.children.length > 0 ? (
            <ul className="text-[red]">{sechandlecat(category.children)}</ul>
          ) : null}
        </li>
      );
    }
    return myCat;
  };

  // const handlSearch = ()=>{
  //   if (searchkey === null){
  //     toast.error('errror search input')
  //     return ''

  //   }
  //   else{
  //     return searchkey
  //   }
  // }

  const item = {
    id: 4,
    title: { arabic: "this is arabic", bangla: "সালাতে বুকের উপর হাত বাঁধা" },
    narrator: "Ali Radiallahu Anhu",
    isRewyat: {
      isMaqbool: true,
      isMardood: false,
    },
    type: {
      isMarfo: true,
      isMawqof: false,
      isMaqto: false,
      isQaul: false,
    },
    category: ["সালাত", "হাত বাঁধা"],
  };
  return !tahqeeqs ? (
    <Loader />
  ) : (
    // <div className=" container w-[95%] md:w-[85%] m-auto pt-3 ">
    <div className="">
      {/* <Pagename name={"তাহকিক"} /> */}
      <div className="content grid grid-cols-[1fr] mt-3 md:grid-cols-[1fr_3fr] md:gap-[16px] gap-[15px]">
        <Category categories={categories} />

        <div className="items">
          <div className="flex flex-col gap-4 h-fit searchitem srchdiv ">
            <SearchItem
              placeholder={"শিরোনাম লিখুন/ হাদীসের ইবারত লিখুন..."}
              handelsearch={(e) => settSearch(e.target.value)}
              searchkey={searchkey}
            />

            {/* <Link to={`?search=${searchkey}`}> search</Link> */}

            <div className="flex flex-wrap justify-center gap-4 topbtn">
              <div className="flex gap-1 sahabi md:gap-2">
                <Link
                  className={`  text-[14px] md:text-[18px] p-[0px_14px] rounded-sm h-fit  text-[#303030]   hover:bg-[#ffffff] relative shadow-[0px_1px_3px_0px_#e3e3e3] group  `}
                  to={`?filter=isMaqbool`}
                >
                  {/* <div className="circle absolute bg-red-500 h-[77%] w-[22%] rounded-full top-[2px] left-[12px] "></div> */}
                  <FontAwesomeIcon
                    icon="fa-solid fa-circle-check"
                    className={` ${
                      filter === "isMaqbool"
                        ? "text-[#34ff78]"
                        : "text-[#c9c9c9]"
                    } md:text-[18px]  group-hover:text-[#34ff78]`}
                  />{" "}
                  মাকবুল{" "}
                </Link>

                <Link
                  className={`${
                    filter === "starred" ? "bg-[#dddddd]" : "bg-[#ffffff]"
                  } text-[14px] md:text-[18px] p-[0px_14px] rounded-sm h-fit   text-[#303030]   hover:bg-[#ffffff] relative shadow-[0px_1px_3px_0px_#e3e3e3] group  `}
                  to={`?filter=isMardood`}
                >
                  {/* <div className="circle absolute bg-red-500 h-[77%] w-[22%] rounded-full top-[2px] left-[12px] "></div> */}
                  <FontAwesomeIcon
                    icon="fa-solid fa-circle-xmark "
                    className={` ${
                      filter === "isMardood" ? "text-reddish" : "text-[#c9c9c9]"
                    } md:text-[18px]  group-hover:text-[red]`}
                  />{" "}
                  মারদুদ{" "}
                </Link>
              </div>

              <div className="flex gap-1 sahabi md:gap-2">
                <Link to={`?filter=isMarfo`}>
                  <Cardbtn
                    value={true}
                    color={
                      filter === "isMarfo"
                        ? "bg-marfu md:text-[18px]"
                        : " md:text-[18px] hover:bg-marfu hover:text-white bg-white text-[#303030] shadow-[0px_1px_3px_0px_#e3e3e3]"
                    }
                    text="মারফূ’"
                  />
                </Link>
                <Link to={`?filter=isMawqof`}>
                  <Cardbtn
                    value={true}
                    color={
                      filter === "isMawqof"
                        ? "bg-marfu md:text-[18px]"
                        : " md:text-[18px] hover:bg-marfu hover:text-white bg-white text-[#303030] shadow-[0px_1px_3px_0px_#e3e3e3]"
                    }
                    text="মাউকুফ’"
                  />
                </Link>
                <Link to={`?filter=isMaqtu`}>
                  <Cardbtn
                    value={true}
                    color={
                      filter === "isMaqtu"
                        ? "bg-marfu md:text-[18px]"
                        : " md:text-[18px] hover:bg-marfu hover:text-white bg-white text-[#303030] shadow-[0px_1px_3px_0px_#e3e3e3]"
                    }
                    text="মাকতু’"
                  />
                </Link>
                <Link to={`?filter=isQaul`}>
                  <Cardbtn
                    value={true}
                    color={
                      filter === "isQaul"
                        ? "bg-marfu md:text-[18px]"
                        : " md:text-[18px] hover:bg-marfu hover:text-white bg-white text-[#303030] shadow-[0px_1px_3px_0px_#e3e3e3]"
                    }
                    text="ক্বওল’"
                  />
                </Link>
              </div>
            </div>
          </div>
          <Filtercounter
            value={filter || category || search}
            totalCount={tahqeeqCount}
            text="তাহকিক"
            filterCount={filteredTahqeeqCount}
            resultprpage={resultPerPage}
          />

          <div className="min-h-screen items">
            {tahqeeqs ? (
              tahqeeqs.length !== 0 ? (
                tahqeeqs.map((item, index) => <TahqeeqCard item={item} />)
              ) : (
                <h1>not found sorry</h1>
              )
            ) : (
              <h1>not foundddd sorry</h1>
            )}
          </div>
        </div>
      </div>
      {/* <PaginatedItems itemsPerPage={6} Itemm={Items} key={projects}  projectss={projects}  /> */}

      {resultPerPage < count && (
        <Pagination
          activePage={currentPage}
          itemsCountPerPage={resultPerPage}
          totalItemsCount={count}
          onChange={setCurrentPageNo}
          prevPageText="Prev"
          firstPageText="1st"
          lastPageText="Last"
          nextPageText="Next"
          itemClass="page_item"
          linkClass="page_link"
          activeClass="pageItemActive"
          activeLinkClass="pageLinkActive"
        />
      )}
    </div>
  );
}

export default Tahqeeq;
