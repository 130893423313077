 import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from 'react-headless-accordion';
import { Link } from 'react-router-dom';



 const handlecat = (categories)=>{
    let myCat =[];
    for(let category of categories){
      myCat.push(
        // <Accordiont title={category.name} slug={category.slug} content={category.children.length > 0 ?(<ul>{handlecat(category.children)}</ul>):null} />

        // <Accordiont title={category.name} slug={category.slug} content={category.children.length > 0 ?(<ul>{handlecat(category.children)}</ul>):null} />
        <Accordion>
          
    <AccordionItem className='bg-[#e8ff1b]'>
    {({open}) => (<><AccordionHeader className="flex items-center justify-between w-full p-4 text-gray-600 border-b">{category.parentId ? 
   <><Link to={`?category=${category.slug}`}>
            <h3 className={`accordion-title `}>{category.name}</h3></Link> {category.children.length > 0  && <p style={{ marginLeft: "20px" }}>{open ? <FontAwesomeIcon icon="fa-solid fa-caret-up" /> :  <FontAwesomeIcon icon="fa-solid fa-caret-down" />}</p>} </>:
            
            <><h3 className={`accordion-title `}>{category.name} </h3><p style={{ marginLeft: "20px" }}>{open ? <FontAwesomeIcon icon="fa-solid fa-caret-up" /> : <FontAwesomeIcon icon="fa-solid fa-caret-down" />}</p></> }
        </AccordionHeader>

        <AccordionBody>
            <div className="accordion-body">
              { category.children.length > 0 && <AccordionItem>
                {({open}) => (<> <AccordionHeader className='bg-[#fafafa] ml-4 pr-4 w-full'>
                      { <ul className="ulul" >{handlecat(category.children)}</ul>}
                    {/* {category.children.length > 0 ?(<ul>{handlecat(category.children):null} */}
                    </AccordionHeader></>)}


                   

                    {/* <AccordionBody>
                        <div className="accordion-body">
                            Lorem ipsum dolor sit amet.
                        </div>
                    </AccordionBody> */}
                </AccordionItem>}
                
            </div>
        </AccordionBody></>)}
        
    </AccordionItem>
</Accordion>
        // <li key={category.name}>
        //   {category.name}
        //   {category.children.length > 0 ?(<ul>{handlecat(category.children)}</ul>):null}
        // </li>
    
      )
    }
    return myCat;
  }
 
 const Category = ({categories}) => {
   return (
     <div>
        <div className="category hidden md:block md:row-[1_/_span_2] md:col-span-1 shadow-[0px_1px_6px_0px_#ededed] h-fit">
          <div className="flex items-center justify-center w-full gap-2 p-4 text-2xl text-white cattit bg-theme">
            <FontAwesomeIcon icon="fa-solid fa-tags" />
            <h3 className="">বিষয়ভিত্তিক ক্যাটেগরি</h3>
          </div>

          {categories.length > 0 && <ul>{handlecat(categories)}</ul>}
        </div>
        <div className="block md:hidden">
          <Accordion>
            <AccordionItem>
              {({ open }) => (
                <>
                  <AccordionHeader
                    className={`accordion w-full flex justify-between bg-theme text-[20px] shadow-[0px_2px_5px_0px_#d9d9d9] items-center text-white py-[8px] px-[15px] rounded-[3px]  hover:bg-[#30424e] transition-all duration-300 ease-in-out ${
                      open ? "bg-[#30424e]" : "bg-theme"
                    }`}
                  >
                    <p>বিষয়ভিত্তিক ক্যাটেগরি:</p>
                    <span style={{ marginLeft: "20px" }}>
                      {open ? (
                        <FontAwesomeIcon icon="fa-solid fa-caret-up" />
                      ) : (
                        <FontAwesomeIcon icon="fa-solid fa-caret-down" />
                      )}
                    </span>
                  </AccordionHeader>
                  <AccordionBody>
                    {categories.length > 0 && <ul>{handlecat(categories)}</ul>}
                  </AccordionBody>
                </>
              )}
            </AccordionItem>
          </Accordion>
        </div>
     </div>
   )
 }
 
 export default Category