import React, { useState } from "react";
import { Link, Outlet, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faFileSignature, faUserGroup,faBook, faEnvelope, faXmark } from "@fortawesome/free-solid-svg-icons";
import logo from '../../img/logo.png'

const Navbar = () => {

  //Nav Array
  const n_Array = [

    {
      link: "/tahqeeq",
      name: "তাহকিক",
      icon: faFileSignature,
    },
    {
      link: "/narrators",
      name: "আসমাউর রিজাল",
      icon: faUserGroup,
    },
    {
      link: "/risalah",
      name: "রিসালাহ",
      icon: faBook,
    },
    {
      link: "/contact",
      name: "যোগাযোগ",
      icon: faEnvelope,
    },
  ];

  const [click, setClick] = useState(false);

  //Handle Click for Toggling
  const handleClick = () => setClick(!click);

  return (
    <>
      <div className="">
        <div className="nav_content   text-[20px] text-[#40464e]  font-[500]  shadow-[1px_1px_4px_1px_rgb(0_0_0_/_10%)]  fixed  border-b-2 md:flex md:justify-between md:px-[80px] py-2 items-center z-[10] border-[#ffffff] bg-[#ffffff]  top-0 left-0 right-0">


          <div className="logo flex items-center  h-[40px]">

            <div className="ham_menu  p-6  rounded-[50px] absolute ml-4 cursor-pointer hamburger_icon w-[fit-content] md:hidden"
              onClick={handleClick}>
              <p className="absolute translate-y-[-50%] translate-x-[-50%]">
                {click ? <FontAwesomeIcon icon={faXmark} /> : <FontAwesomeIcon icon={faBars} />}

              </p>
            </div>


            <div className="flex justify-center w-full logo_img ">

              <Link className="flex justify-center md:block" to="/">
                <img className="w-[112px]" src={logo}></img>
                {/* <p className="font-['akhandBangali'] text-[25px]">
                  AHLE TAHQEEQ
                </p> */}
              </Link>
            </div>
          </div>

          <div
            className={
              click
                ? "  absolute left-0 right-0 z-10 transition-all ease-in duration-[0.3s] top-[58px] md:top-0   md:relative  "
                : " transition-all ease-out duration-[0.5s] w-full absolute left-[-100%]  top-[58px] md:top-0  md:block md:relative md:left-0 md:transition-none md:w-[inherit]  "
            }
          >
            <div className="flex nav_c ">
              <ul className="bg-[#ffffff] md:bg-transparent w-[70%] relative  ">
                <div className="n_lists md:flex md:justify-around md:w-[400px]">
                  <div className="flex justify-center w-full p-12 bg-ashes logo_img md:hidden ">

                    <Link className="flex justify-center md:block" to="/">
                      <img className="w-[112px]" src={logo}></img>
                      {/* <p className="font-['akhandBangali'] text-[25px]">
                 AHLE TAHQEEQ
               </p> */}
                    </Link>
                  </div>

                  {n_Array.map((item, i) => {
                    return (
                      <div key={i}>
                        <li
                          onClick={handleClick}
                          className={`  border-b-2  md:bg-none md:hover:bg-none bg-gradient-to-r from-[#fdfdfd] to-[#ffffff] border-[#efefef] hover:bg-gradient-to-l text-[#40464e]  md:border-none flex items-center navnav  `}
                        >
                          <NavLink
                            className={(navData) =>
                              navData.isActive
                                ? " p-[12px_16px]   md:p-[1px_12px] w-full md:w-[fit-content] md:bg-theme md:text-white  shadow-[1px_1px_2px_0px_#dddddd]   md:rounded-[20px] md:hover:text-white md:text-lg  "
                                : "p-[12px_16px]  md:p-[1px_12px] w-full md:w-[fit-content]  md:hover:bg-theme  md:hover:shadow-[1px_1px_2px_0px_#dddddd] md:text-lg md:rounded-[20px] text-[#40464e] md:hover:text-white  "
                            }
                            to={item.link}
                          >
                            {/* <span>{item.name}</span> */}
                            <div className=" md:pl-0">
                              <span className="p-[10px] md:hidden ">
                              <FontAwesomeIcon icon={item.icon} />
                                {/* <FontAwesomeIcon icon={["fas", item.icon]} /> */}
                              </span>
                              <span className="font-['books']">{item.name}</span>

                            </div>


                          </NavLink>
                        </li>
                      </div>
                    );
                  })}
                  <div
                    className="footer md:hidden absolute bottom-[70px] justify-between items-center bg-[#313131] p-[15px_25px] font-['monoserrat']  w-full
            "
                  >
                    <div className="logo w-[70px] cursor-pointer">
                      <img src="img/Component4_1.png" alt="" />
                    </div>
                    <h2 className="text-white text-[10px]">
                      {" "}
                      2022 | All right reserved.
                    </h2>
                  </div>
                </div>
                {/* <div className="footer md:hidden  flex justify-between items-center bg-[#0e122d] p-[1px_25px] fixed bottom-0 w-full
            ">
              <div className="logo w-[70px] cursor-pointer">
                <img src="img/Component4_1.png" alt="" />
              </div>
              <h2 className="text-white text-[10px]"> 2022 | All right reserved.</h2>
            </div> */}
              </ul>
              <div
                onClick={handleClick}
                // className="w-[30%] h-[100vh] blur_div bg-[#afafaf] opacity-25 md:hidden"
                className={
                  click
                    ? "w-[30%] h-[100vh] blur_div bg-[#afafaf] opacity-25 md:hidden "
                    : "w-[30%] h-[100vh] blur_div bg-[#afafaf] opacity-0 md:hidden  "
                }
              ></div>
            </div>
          </div>
        </div>
        <Outlet />
      </div>
    </>
  );
};

export default Navbar;
